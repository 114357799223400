var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "c-cards" }, [
    _c("div", { staticClass: "c-card" }, [
      _c(
        "div",
        { staticClass: "c-card__content" },
        [
          _c(
            "content-placeholders",
            [
              _c("content-placeholders-heading", { attrs: { img: true } }),
              _vm._v(" "),
              _c("content-placeholders-text", { attrs: { lines: 3 } }),
              _vm._v(" "),
              _c("div", { staticClass: "placeholder-icons" }, [
                _c("div", {
                  staticClass: "vue-content-placeholders-heading__img",
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "vue-content-placeholders-heading__img",
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "vue-content-placeholders-heading__img",
                }),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "c-card" }, [
      _c(
        "div",
        { staticClass: "c-card__content" },
        [
          _c(
            "content-placeholders",
            [
              _c("content-placeholders-heading", { attrs: { img: true } }),
              _vm._v(" "),
              _c("content-placeholders-text", { attrs: { lines: 5 } }),
              _vm._v(" "),
              _c("div", { staticClass: "placeholder-icons" }, [
                _c("div", {
                  staticClass: "vue-content-placeholders-heading__img",
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "vue-content-placeholders-heading__img",
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "vue-content-placeholders-heading__img",
                }),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "c-card" }, [
      _c(
        "div",
        { staticClass: "c-card__content" },
        [
          _c(
            "content-placeholders",
            [
              _c("content-placeholders-heading", { attrs: { img: true } }),
              _vm._v(" "),
              _c("content-placeholders-text", { attrs: { lines: 3 } }),
              _vm._v(" "),
              _c("div", { staticClass: "placeholder-icons" }, [
                _c("div", {
                  staticClass: "vue-content-placeholders-heading__img",
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "vue-content-placeholders-heading__img",
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "vue-content-placeholders-heading__img",
                }),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "c-card" }, [
      _c(
        "div",
        { staticClass: "c-card__content" },
        [
          _c(
            "content-placeholders",
            [
              _c("content-placeholders-heading", { attrs: { img: true } }),
              _vm._v(" "),
              _c("content-placeholders-text", { attrs: { lines: 4 } }),
              _vm._v(" "),
              _c("div", { staticClass: "placeholder-icons" }, [
                _c("div", {
                  staticClass: "vue-content-placeholders-heading__img",
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "vue-content-placeholders-heading__img",
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "vue-content-placeholders-heading__img",
                }),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }